((document) => {
  //   get the array of src images
  const billboardImages = document.querySelectorAll('.js-buy-car picture source');

  const imageUrls = [...billboardImages].map((billboardImage) =>
    billboardImage.getAttribute('srcset'),
  );

  const smcImageUrls = [...document.querySelectorAll('.js-sell-my-car picture source')].map(
    (billboardImage) => billboardImage.getAttribute('srcset'),
  );

  // Select the tabs containers
  const buySellWidget = document.querySelector('.js-buy-sell');
  const billboardContent = document.querySelector('.js-billboard-content');

  function toggleBillboardImages(event) {
    try {
      const clickedEl = event.target;
      const isWithinBuySellWidget = clickedEl.closest('.js-buy-sell');

      // Check if the clicked element is within the buySellWidget
      if (!isWithinBuySellWidget) {
        // If not, check if the clicked element is an image element
        if (clickedEl.tagName === 'IMG') {
          // If yes, prevent changing the image
          event.stopPropagation();
          return;
        }
        // Otherwise, do nothing
        return;
      }
      // If the click is not within the buySellWidget or it's not an anchor tag, do nothing
      if (clickedEl.tagName !== 'A') {
        return;
      }

      const buttonText = clickedEl.innerHTML;
      if (buttonText === undefined) {
        throw new Error('innerHTML property is undefined');
      }
      [...billboardImages].forEach((billboardImage, index) => {
        billboardImage.setAttribute(
          'srcset',
          buttonText === 'Sell' ? smcImageUrls[index] : imageUrls[index],
        );
      });
    } catch (err) {
      console.error('Error: ', err.message);
    }
  }

  const bilboardContentCaption = document.querySelector('.billboard-content__caption');

  if (buySellWidget && billboardContent && bilboardContentCaption) {
    document.addEventListener('click', toggleBillboardImages);
  }
})(document);
